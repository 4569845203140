<template>
	<div class="order-auto-complete-exclude-sku">
		<CRow class="mb-3">
			<CCol md="8">
				<h6>
					Exclude SKU
				</h6>
				<div class="typo-body-2 color-black-45">
					Add an SKU for this to exclude the product from the auto-component worker.
				</div>
			</CCol>
			<CCol md="4" class="text-right">
				<CButton
					color="secondary"
					class="btn btn-secondary"
					@click="$refs['modal-sku'].open()"
				>
					{{ excludeSku.length ? 'Edit' : 'Add' }} sku
				</CButton>
			</CCol>
		</CRow>
		<template v-if="!excludeSku.length">
			<BaseNoItemBanner
				text="There are no exclude SKU for this setting"
			/>
		</template>
		<template v-else>
			<div class="list-item">
				<p class="typo-body-2 color-black-45">{{ skuListText }}</p>
			</div>
		</template>
		<hr class="mb-4">
		<ModalSkuList
			ref="modal-sku"
			title="Add SKU"
			add-button-text="Add"
			:sku-list="excludeSku"
			@onConfirm="handleSubmitModal"
		/>
	</div>
</template>
<script>
import ModalSkuList from '@/components/ModalSkuList.vue';

export default {
	name: 'OrderAutoCompleteExcludeSKU',
	components: { ModalSkuList },
	props: {
		excludeSku: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		skuListText() {
			return this.excludeSku.join('; ');
		},
	},
	methods: {
		handleSubmitModal(skus = []) {
			this.$emit('update:excludeSku', skus);
		},
	},
};
</script>
<style lang="scss" scoped>
.list-item {
	padding: rem(20) rem(16) 0;
	max-height: rem(320);
	overflow: auto;
	border: solid 1px $color-gray-400;
	border-radius: rem(4);
}
</style>
